import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTermAndConditionsSections } from '../../hooks';

const useStyles = makeStyles(() => ({
  titleSection: {
    margin: '1rem 0 0.5rem',
  },
  content: {
    padding: '1.5rem',
    margin: '0.5rem',
  },
  offerButton: {
    width: '10%',
    fontSize: '15px',
    borderRadius: '20px',
    marginBottom: '0.5rem',
  },
}));

const TermsAndConditionsDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const { loading, error, termSections } = useTermAndConditionsSections();

  if (error) return `Error! ${error.message}`;

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h5" color="primary" className={classes.titleSection}>
          Términos y Condiciones
        </Typography>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        {loading ? (
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : (
          termSections
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          className={classes.offerButton}
          onClick={() => setOpen(false)}
          id="close-terms-and-conditions"
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TermsAndConditionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default TermsAndConditionsDialog;
