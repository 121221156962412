import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { Button, CircularProgress, MobileStepper } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FingoDialog } from '.';

const StepperDialog = ({ open, setOpen, handleClose, steps, ...dialogProps }) => {
  const [activeStep, setActiveStep] = useState(0);

  const onClose = () => {
    handleClose();
    setActiveStep(0);
    setOpen(false);
  };

  const backButtonClickHandler = () => {
    steps[activeStep].backButton?.handler?.();
    if (activeStep === 0) {
      onClose();
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const nextButtonClickHandler = async () => {
    await steps[activeStep].nextButton?.handler?.();
    if (activeStep !== steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      onClose();
    }
  };
  return (
    <FingoDialog
      open={open}
      handleClose={onClose}
      title={steps[activeStep].title}
      subtitle={steps[activeStep].subtitle}
      icon={steps[activeStep].icon}
      maxWidth={steps[activeStep].maxWidth}
      fullWidth={steps[activeStep].fullWidth}
      dialogAlert={steps[activeStep].dialogAlert}
      setDialogAlert={steps[activeStep].setDialogAlert}
      dialogActionButton={(
        <MobileStepper
          variant="dots"
          steps={steps.length}
          position="static"
          sx={{ width: '100%' }}
          activeStep={activeStep}
          nextButton={(
            <Button
              size="small"
              onClick={nextButtonClickHandler}
              disabled={
                steps[activeStep].nextButton?.disabled
                || steps[activeStep].nextButton?.loading
                || false
              }
            >
              {steps[activeStep].nextButton.loading ? (
                <CircularProgress />
              ) : (
                <>
                  {steps[activeStep].nextButton.label} <KeyboardArrowRight />
                </>
              )}
            </Button>
          )}
          backButton={(
            <Button
              size="small"
              onClick={backButtonClickHandler}
              disabled={steps[activeStep].backButton?.disabled || false}
            >
              <KeyboardArrowLeft /> {steps[activeStep].backButton.label}
            </Button>
          )}
        />
      )}
      {...dialogProps}
    >
      {steps[activeStep].component}
    </FingoDialog>
  );
};

StepperDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      dialogAlert: PropTypes.shape({
        message: PropTypes.string,
        severity: PropTypes.string,
        show: PropTypes.bool,
      }),
      setDialogAlert: PropTypes.func,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      icon: PropTypes.element,
      component: PropTypes.element,
      maxWidth: PropTypes.string,
      fullWidth: PropTypes.bool,
      stepLevel: PropTypes.number,
      nextButton: PropTypes.shape({
        disabled: PropTypes.bool,
        label: PropTypes.string,
        loading: PropTypes.bool,
        handler: PropTypes.func,
      }),
      backButton: PropTypes.shape({
        disabled: PropTypes.bool,
        label: PropTypes.string,
        handler: PropTypes.func,
      }),
    }),
  ).isRequired, // eslint-disable-line react/forbid-prop-types
  handleClose: PropTypes.func.isRequired,
};

export default StepperDialog;
