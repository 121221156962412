import React, { useMemo } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { Alert, IconButton, SnackbarContent, Stack, keyframes } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Mail from '@mui/icons-material/Mail';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import useLocalStorageTimer from '../../hooks/useLocalStorageTimer';
import { useBooleanState, useGetUser, useIsMobile, useSelectedCompany } from '../../hooks';
import { SEND_EMAIL_VERIFICATION_MAIL } from '../../graphql';
import TransitionLeft from '../animations/TransitionLeft';

const rotation = 1;

const myKeyframe = keyframes`
    0 %  { transform: translate(1px, 1px)   rotate(0deg)    },
    50%  { transform: translate(-1px, 1px)  rotate(-${rotation}deg);  },
`;

export default function MailVerificationSnackBar() {
  const {
    lastTimeStored: lastMailVerificationShowed,
    resetTimer: resetMailVerificationShowed,
  } = useLocalStorageTimer('MAIL_VERIFICATION');
  const [success, setSuccess] = useBooleanState();
  const currentUser = useGetUser();
  const selectedCompany = useSelectedCompany();
  const openSnackbar = useMemo(() => (
    moment().diff(lastMailVerificationShowed, 'minutes') > 60
     && currentUser
     && !currentUser.isEmailVerified
     && selectedCompany?.hasLendingAcceptedterms
  ), [lastMailVerificationShowed]);
  const [sendEmailVerificationMail] = useMutation(
    SEND_EMAIL_VERIFICATION_MAIL,
    {
      onCompleted: () => {
        setSuccess();
        resetMailVerificationShowed();
      },
    },
  );
  const isMobile = useIsMobile();
  if (isMobile) return <></>;
  return (
    <>
      <Snackbar
        open={openSnackbar}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          animation: `${myKeyframe} 1s infinite ease`,
        }}
      >
        <SnackbarContent
          sx={{ mt: 4 }}
          message={(
            <Stack direction="row" alignItems="center">
              <Mail sx={{ color: 'white' }} />
              <Button
                size="small"
                onClick={sendEmailVerificationMail}
                variant="link"
                sx={{ color: 'white' }}

              >
                Verifica tu dirección de correo electrónico
              </Button>
              <IconButton
                sx={{ color: 'white' }}
                onClick={resetMailVerificationShowed}
              >
                <Close />
              </IconButton>
            </Stack>
          )}
        />
      </Snackbar>
      <Snackbar
        open={success}
        TransitionComponent={TransitionLeft}
        onClose={setSuccess}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert
          severity="success"
          sx={{ width: '100%', mt: 4 }}
          onClose={setSuccess}
        >
          Correo de validación envíado con éxito!
        </Alert>
      </Snackbar>
    </>
  );
}
