import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from '@mui/material';

const AlertOneDialog = ({ open, onClose, message, title, showButton }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      {message}
    </DialogContent>
    {showButton && (
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          Entendido
        </Button>
      </DialogActions>
    )}
  </Dialog>
);

AlertOneDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  showButton: PropTypes.bool,
};
AlertOneDialog.defaultProps = {
  showButton: true,
};

export default AlertOneDialog;
