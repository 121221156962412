import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Stack, Typography } from '@mui/material';
import { useIsMobile } from '../../hooks';
import NationalTaxServiceLogoAndText from '../text/NationalTaxServiceLogoAndText';
import NationalTaxServiceCredentialsForm from '../forms/NationalTaxServiceCredentialsForm';

const NationalTaxServiceCredentialsDialog = ({ source, onCompleted, onError }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Card
      width="100%"
      sx={(theme) => ({
        px: 4,
        background: {
          xs: 'white',
          md: `linear-gradient(295deg, ${theme.palette.primary.main} 57%, white 57%)`,
        },
        borderRadius: '20px',
        overflow: { xs: 'scroll', md: 'hidden' },
      })}
      elevation={4}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 4, px: { xs: 6, md: 0 } }}
        width="100%"
        spacing={3}
      >
        {isMobile && (
          <Typography variant="h5" align="center" color="text.main" sx={{ px: 6 }} fontWeight={600}>
            Ingresa las credenciales del <br />{' '}
            <strong>{t('National Tax Service credentials')}</strong> de tu empresa
          </Typography>
        )}
        <NationalTaxServiceLogoAndText width={isMobile ? '100%' : '36%'} />
        <NationalTaxServiceCredentialsForm
          source={source}
          onCompleted={onCompleted}
          onError={onError}
        />
      </Stack>
    </Card>
  );
};

NationalTaxServiceCredentialsDialog.propTypes = {
  source: PropTypes.string,
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
};

NationalTaxServiceCredentialsDialog.defaultProps = {
  source: null,
  onCompleted: () => {},
  onError: () => {},
};

export default NationalTaxServiceCredentialsDialog;
