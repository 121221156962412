import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { useQuery } from '@apollo/client';
import { CircularProgress, Container } from '@mui/material';

const MailPreviewDialog = ({ query, variables }) => {
  const { data, loading, error } = useQuery(query, { variables });

  if (error) {
    return <Container>Hubo un error al desplegar la vista previa del mail</Container>;
  }

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {loading ? <CircularProgress /> : parse(data.collectionMailPreview)}
    </Container>
  );
};

MailPreviewDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  variables: PropTypes.object.isRequired,
};

export default MailPreviewDialog;
